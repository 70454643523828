import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import { saveOrderType } from "../../../store/actions/cart";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { fontName } from "../../../constants/utils";

const useStyles = makeStyles(CartStyle);

const useDynamicStyles = makeStyles({
  selected: (props) => ({
    backgroundColor: props.selectedBgColor + " !important",
    color: props.selectedTextColor + " !important",
  }),
});

export default function CartOrderType({ displayHome, ...props }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);

  const {
    ui_strings,
    enable_delivery,
    enable_takeaway,
    enable_dine_in,
    enable_drive_thru,
    theme_data,
  } = menuInfo.menuData;
  const {
    enableWishlist,
    orderType: cOrderType,
    products,
    orderLocation,
    orderLocationType,
  } = cartInfo;

  const [orderType, setOrderType] = useState(cOrderType);
  const orderTypeEnabled = [
    enable_delivery,
    enable_takeaway,
    enable_dine_in,
    enable_drive_thru,
  ];
  const orderTypeEnabledCount = orderTypeEnabled.filter(Boolean).length;

  const handleOrderType = (event, value) => {
    if (value) {
      setOrderType(value);
    }
  };

  const dynamicStyles = useDynamicStyles({
    selectedBgColor: theme_data?.cart_screen_order_type_btn_selected_color,
    selectedTextColor:
      theme_data?.cart_screen_order_type_btn_selected_text_color,
    fontSize: Number(theme_data?.cart_screen_order_type_btn_selected_font_size),
    ...fontName(theme_data?.cart_screen_order_type_btn_selected_font_family),
  });

  const toggleButtonStyle = {
    backgroundColor: theme_data?.cart_screen_order_type_btn_color,
    color: theme_data?.cart_screen_order_type_btn_text_color,
    fontSize: Number(theme_data?.cart_screen_order_type_btn_font_size),
    ...fontName(theme_data?.cart_screen_order_type_btn_font_family),
    textTransform: theme_data?.cart_screen_toggle_btn_text_transform
      ? theme_data.cart_screen_toggle_btn_text_transform
      : "uppercase",
    fontWeight: displayHome && "normal",
    letterSpacing: displayHome && "2px",
    minWidth: displayHome,
    margin: 4,
    flex: orderTypeEnabledCount > 1 ? 1 : 0,
  };

  useEffect(() => {
    if (!enable_delivery && cOrderType === "delivery") {
      if (enable_dine_in) {
        setOrderType("dine_in");
      } else if (enable_drive_thru) {
        setOrderType("drive_thru");
      } else if (enable_takeaway) {
        setOrderType("takeaway");
      }
    }
  }, [
    cOrderType,
    enable_delivery,
    enable_takeaway,
    enable_dine_in,
    enable_drive_thru,
  ]);

  useEffect(() => {
    dispatch(saveOrderType(orderType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType]);

  return (!enableWishlist &&
    !orderLocationType &&
    !orderLocation &&
    products.length > 0) ||
    displayHome ? (
    <ToggleButtonGroup
      value={orderType}
      exclusive
      onChange={handleOrderType}
      size={"small"}
      aria-label={ui_strings?.ui_str_order_type ?? "Order Type"}
      className={classes.orderTypeContainer}
      style={{
        marginTop: displayHome ? "20px" : "0px",
        marginBottom: displayHome ? "-30px" : "0px",
      }}
    >
      {enable_delivery && (
        <ToggleButton
          value={"delivery"}
          aria-label={ui_strings?.ui_str_delivery ?? "Delivery"}
          selected={orderType === "delivery"}
          classes={{
            root: classes.toggleButton,
            selected: dynamicStyles.selected,
          }}
          style={toggleButtonStyle}
        >
          {ui_strings?.ui_str_delivery ?? "Delivery"}
        </ToggleButton>
      )}
      {enable_dine_in && (
        <ToggleButton
          value={"dine_in"}
          aria-label={ui_strings?.ui_str_dine_in ?? "Dine-in"}
          selected={orderType === "dine_in"}
          classes={{
            root: classes.toggleButton,
            selected: dynamicStyles.selected,
          }}
          style={toggleButtonStyle}
        >
          {ui_strings?.ui_str_dine_in ?? "Dine-in"}
        </ToggleButton>
      )}
      {enable_takeaway && (
        <ToggleButton
          value={"takeaway"}
          aria-label={ui_strings?.ui_str_takeaway ?? "Takeaway"}
          selected={orderType === "takeaway"}
          classes={{
            root: classes.toggleButton,
            selected: dynamicStyles.selected,
          }}
          style={toggleButtonStyle}
        >
          {ui_strings?.ui_str_takeaway ?? "Takeaway"}
        </ToggleButton>
      )}
      {enable_drive_thru && (
        <ToggleButton
          value={"drive_thru"}
          aria-label={ui_strings?.ui_str_drive_thru ?? "Drive-thru"}
          selected={orderType === "drive_thru"}
          classes={{
            root: classes.toggleButton,
            selected: dynamicStyles.selected,
          }}
          style={toggleButtonStyle}
        >
          {ui_strings?.ui_str_drive_thru ?? "Drive-thru"}
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  ) : null;
}
