import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CheckoutStyle from "../../../views/CheckoutPage/CheckoutStyle";
import { css } from "@emotion/css";
import CustomInput from "../../CustomInput/CustomInput";
import { Card, SvgIcon } from "@material-ui/core";
import { getExampleNumber, isValidNumber } from "libphonenumber-js";
import CustomDateTimePicker from "../CustomDateTimePicker/CustomDateTimePicker";

import { Controller } from "react-hook-form";
import { ReactComponent as BlueRewardsIcon } from "../../../assets/svg/blue-rewards.svg";
import examples from "libphonenumber-js/examples.mobile.json";

const useStyles = makeStyles(CheckoutStyle);

export default function CheckoutData(props) {
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const { control } = props;
  const { menuData, selected_language } = menuInfo;
  const {
    enable_delivery_datetime,
    ui_strings,
    theme_data,
    enable_order_email,
    enable_order_phone,
    enable_address_google_maps,
    al_futtaim_enabled,
    menu_country,
  } = menuData;
  const {
    enableWishlist,
    orderType,
    deliveryFormattedAddress,
    disableCustomerPhone,
  } = cartInfo;

  const deliveryDateTimeString =
    ui_strings?.ui_str_delivery_date_time ?? "Delivery Date/Time";

  const takeawayDateTimeString =
    ui_strings?.ui_str_takeaway_date_time ?? "Takeaway Date/Time";

  return (
    !enableWishlist && (
      <Card className={classes.card}>
        {!al_futtaim_enabled && (
          <Controller
            name="name"
            render={({ value, onChange, name }, { invalid, isDirty }) => (
              <CustomInput
                id={name}
                labelText={ui_strings?.ui_str_name ?? "Name"}
                formControlProps={{ fullWidth: true }}
                success={!invalid && isDirty}
                inputProps={{
                  value: value,
                  type: "text",
                  onChange: onChange,
                  className: css`
                    &::after {
                      border-bottom: 2px solid
                        ${theme_data?.cart_screen_form_input_border_color};
                    }
                  `,
                }}
              />
            )}
            control={control}
            rules={{
              required: { value: true, message: `Name is a required field` },
              minLength: {
                value: 3,
                message: `Valid name is required`,
              },
            }}
          />
        )}

        {enable_order_email && (
          <Controller
            name="email"
            render={({ value, onChange, name }, { invalid, isDirty }) => (
              <CustomInput
                id={name}
                labelText={ui_strings?.ui_str_email ?? "Email"}
                formControlProps={{ fullWidth: true }}
                success={!invalid && isDirty}
                error={invalid}
                inputProps={{
                  value: value,
                  type: "email",
                  onChange: onChange,
                  className: css`
                    &::after {
                      border-bottom: 2px solid
                        ${theme_data?.cart_screen_form_input_border_color};
                    }
                  `,
                }}
              />
            )}
            control={control}
            rules={{
              required: { value: true, message: `Name is a required field` },
              pattern: {
                value: /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                message: "Invalid email address",
              },
            }}
          />
        )}
        {enable_order_phone && (
          <Controller
            name="phone"
            render={({ value, onChange, name }, { invalid, isDirty }) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomInput
                  id={name}
                  labelText={ui_strings?.ui_str_phone ?? "Phone"}
                  formControlProps={{ fullWidth: true }}
                  success={!invalid && isDirty}
                  error={invalid}
                  labelProps={{
                    style: {
                      minWidth: "390px",
                    },
                  }}
                  inputProps={{
                    value: value,
                    type: "tel",
                    placeholder: `${
                      getExampleNumber(menu_country, examples).number
                    }`,
                    onChange: onChange,
                    disabled: disableCustomerPhone,
                    className: css`
                      * {
                        direction: ${["ar", "he"].includes(selected_language)
                          ? "rtl"
                          : "ltr"};
                      }
                      ,
                      &::after {
                        border-bottom: 2px solid
                          ${theme_data?.cart_screen_form_input_border_color};
                      }
                    `,
                  }}
                />
                {al_futtaim_enabled && (
                  <SvgIcon
                    component={BlueRewardsIcon}
                    viewBox="0 0 40 40"
                    style={{
                      fontSize: "44px",
                    }}
                  />
                )}
              </div>
            )}
            control={control}
            rules={{
              required: {
                value: !(menu_country === "SG" && al_futtaim_enabled),
                message: `Phone is a required field`,
              },
              validate: (value) => {
                if (value) {
                  if (isValidNumber(value, menu_country)) {
                    return true;
                  } else {
                    return `Please enter valid phone number`;
                  }
                }
                return true;
              },
            }}
          />
        )}
        {orderType === "drive_thru" && (
          <Controller
            name="carNumber"
            render={({ value, onChange, name }, { invalid, isDirty }) => (
              <CustomInput
                id={name}
                labelText={ui_strings?.ui_str_car_number ?? "Car Number"}
                formControlProps={{ fullWidth: true }}
                success={!invalid && isDirty}
                inputProps={{
                  value: value,
                  type: "text",
                  placeholder: "A 12345",
                  onChange: onChange,
                  className: css`
                    &::after {
                      border-bottom: 2px solid
                        ${theme_data?.cart_screen_form_input_border_color};
                    }
                  `,
                }}
              />
            )}
            control={control}
            rules={{
              required: { value: true, message: `Name is a required field` },
              minLength: {
                value: 3,
                message: `Valid car number is required`,
              },
            }}
          />
        )}
        {enable_delivery_datetime &&
          (orderType === "delivery" || orderType === "takeaway") && (
            <Controller
              name="customDateTime"
              render={({ value, onChange, name }, { invalid, isDirty }) => (
                <CustomDateTimePicker
                  id={name}
                  labelText={
                    orderType === "delivery"
                      ? deliveryDateTimeString
                      : takeawayDateTimeString
                  }
                  formControlProps={{ fullWidth: true }}
                  success={!invalid && isDirty}
                  inputProps={{
                    value: value,
                    minDate: new Date(),
                    onChange: onChange,
                    className: css`
                      &::after {
                        border-bottom: 2px solid
                          ${theme_data?.cart_screen_form_input_border_color};
                      }
                    `,
                  }}
                />
              )}
              control={control}
              defaultValue={new Date()}
              rules={{
                required: { value: true, message: `Date is a required field` },
                validate: (value) => {
                  if (value) {
                    if (value instanceof Date) {
                      return true;
                    } else {
                      return `Please select a valid date and time`;
                    }
                  }
                  return true;
                },
              }}
            />
          )}
        {!enable_address_google_maps && orderType === "delivery" && (
          <Controller
            name="address"
            render={({ value, onChange, name }, { invalid, isDirty }) => (
              <CustomInput
                id={name}
                labelText={ui_strings?.ui_str_address ?? "Address"}
                formControlProps={{ fullWidth: true }}
                success={!invalid && isDirty}
                inputProps={{
                  value: value,
                  type: "text",
                  onChange: onChange,
                  multiline: true,
                  rows: 3,
                  placeholder:
                    ui_strings?.ui_str_order_address_placeholder ??
                    "Flat No. / Building Name / Street Name\nArea / Locality / Landmark\nCity / State / Country",
                  className: css`
                    &::after {
                      border-bottom: 2px solid
                        ${theme_data?.cart_screen_form_input_border_color};
                    }
                  `,
                }}
              />
            )}
            control={control}
            defaultValue={deliveryFormattedAddress}
            rules={{
              required: { value: true, message: `Address is a required field` },
              minLength: {
                value: 3,
                message: `Address is required for delivery`,
              },
            }}
          />
        )}
      </Card>
    )
  );
}
