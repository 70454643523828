import React from "react";
import {
  convertNumerals,
  currency_symbol,
  fontName,
} from "../../../constants/utils";
import { useSelector } from "react-redux";

export default function ProductPricing(props) {
  const { product, price, discountedPrice } = props;
  const menuInfo = useSelector((state) => state.menuInfo);

  const { selected_language, menuData } = menuInfo;

  const {
    ui_strings,
    theme_data,
    price_decimal_places,
    display_currency,
    currency,
    currency_symbol: cSymbol,
    enable_alt_currency,
    alt_currency,
    alt_currency_symbol,
    alt_currency_price_decimal_places,
    alt_currency_exchange_rate,
    menu_country,
    enable_arabic_numerals,
    enable_discount_percentage,
  } = menuData;

  const productOldPriceStyle = {
    textDecoration: "line-through",
  };

  const productDiscountStyle = {
    color: theme_data?.product_screen_product_discount_text_color,
    fontSize: Number(theme_data?.product_screen_product_discount_font_size),
    ...fontName(theme_data?.product_screen_product_discount_font_family),
  };

  const productDiscountedPriceStyle = {
    color: theme_data?.product_screen_product_discount_price_text_color,
    fontSize: Number(
      theme_data?.product_screen_product_discount_price_font_size
    ),
    ...fontName(theme_data?.product_screen_product_discount_price_font_family),
  };

  return (
    <div>
      <span style={discountedPrice > 0 ? productOldPriceStyle : null}>
        {display_currency
          ? cSymbol
            ? cSymbol
            : currency_symbol(currency)
          : null}
        {convertNumerals(
          parseFloat(price).toFixed(price_decimal_places),
          selected_language,
          menu_country,
          enable_arabic_numerals,
          price_decimal_places
        )}
      </span>{" "}
      {discountedPrice > 0 ? (
        <span style={productDiscountedPriceStyle}>
          {display_currency
            ? cSymbol
              ? cSymbol
              : currency_symbol(currency)
            : null}
          {convertNumerals(
            parseFloat(discountedPrice).toFixed(price_decimal_places),
            selected_language,
            menu_country,
            enable_arabic_numerals,
            price_decimal_places
          )}
        </span>
      ) : null}
      {enable_alt_currency && alt_currency && alt_currency_exchange_rate && (
        <div>
          <span style={discountedPrice > 0 ? productOldPriceStyle : null}>
            {alt_currency_symbol
              ? alt_currency_symbol
              : currency_symbol(alt_currency)}
            {convertNumerals(
              parseFloat(price * alt_currency_exchange_rate).toFixed(
                alt_currency_price_decimal_places
              ),
              selected_language,
              menu_country,
              enable_arabic_numerals,
              price_decimal_places
            )}
          </span>{" "}
          {discountedPrice > 0 ? (
            <span style={productDiscountedPriceStyle}>
              {alt_currency_symbol
                ? alt_currency_symbol
                : currency_symbol(alt_currency)}
              {convertNumerals(
                parseFloat(
                  discountedPrice * alt_currency_exchange_rate
                ).toFixed(alt_currency_price_decimal_places),
                selected_language,
                menu_country,
                enable_arabic_numerals,
                price_decimal_places
              )}
            </span>
          ) : null}
        </div>
      )}
      {discountedPrice > 0 && enable_discount_percentage && (
        <div style={productDiscountStyle}>
          {convertNumerals(
            product.discount,
            selected_language,
            menu_country,
            enable_arabic_numerals,
            0
          )}
          {"% "}
          {ui_strings?.ui_str_discount ?? "Discount"}
        </div>
      )}
    </div>
  );
}
