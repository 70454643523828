import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import { css } from "@emotion/css";
import CustomInput from "../../CustomInput/CustomInput";
import { saveOrderComments } from "../../../store/actions/cart";

const useStyles = makeStyles(CartStyle);

export default function CartOrderComments(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);

  const { ui_strings, theme_data, enable_order_comments } = menuInfo.menuData;
  const { orderComments: cOrderComments, enableWishlist } = cartInfo;

  const [orderComments, setOrderComments] = useState(cOrderComments);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setOrderComments(value);
  };

  useEffect(() => {
    if (orderComments) {
      dispatch(saveOrderComments(orderComments));
    } else {
      dispatch(saveOrderComments(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderComments]);

  return !enableWishlist && enable_order_comments ? (
    <CustomInput
      id={"order_comments"}
      labelText={ui_strings?.ui_str_order_comments ?? "Order Comments"}
      formControlProps={{
        fullWidth: true,
        className: classes.orderCommentContainer,
      }}
      success={!!orderComments}
      labelProps={{
        classes: {
          root: classes.orderCommentLabel,
        },
      }}
      inputProps={{
        value: orderComments,
        type: "text",
        onChange: handleInputChange,
        multiline: true,
        rows: 1,
        placeholder:
          ui_strings?.ui_str_order_comments_placeholder ??
          "Add additional comments here",
        className: css`
          &::after {
            border-bottom: 2px solid
              ${theme_data?.cart_screen_form_input_border_color};
          }
        `,
      }}
    />
  ) : null;
}
