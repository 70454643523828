import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Emenu/Header/Header.js";
import DeliveryStyle from "./DeliveryStyle";
import { useSelector } from "react-redux";
import firebase from "../../constants/Firebase";
import { Helmet } from "react-helmet";
import CartMap from "../../components/Emenu/Cart/CartMap";

const useStyles = makeStyles(DeliveryStyle);

export default function DeliveryPage(props) {
  const menuInfo = useSelector((state) => state.menuInfo);
  const { ui_strings, theme_data, license } = menuInfo.menuData;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.body.style.backgroundColor = theme_data?.cart_screen_bg_color;
    const rootElement = document.getElementById("root");
    if (rootElement) {
      rootElement.style.backgroundColor = theme_data?.cart_screen_bg_color;
    }

    if (license) {
      firebase.analytics().logEvent("delivery_page_view", {
        license: license,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <Header
        goback
        brand={ui_strings?.ui_str_delivery_location ?? "Delivery Location"}
      />
      <Helmet>
        <title>
          {menuInfo.menuData?.name
            ? `${
                ui_strings?.ui_str_delivery_location ?? "Delivery Location"
              } | ${menuInfo.menuData?.name} | digitalemenu.com`
            : "digitalemenu.com"}
        </title>
      </Helmet>
      <div className={classes.main}>
        <div className={classes.container}>
          <CartMap />
        </div>
      </div>
    </div>
  );
}
