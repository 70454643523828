import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import OnboardingStyle from "./OnboardingStyle";
import { changeLanguage, setMenuFormSubmitted } from "../../store/actions/menu";
import Button from "components/CustomButtons/Button";
import { fontName, isEmpty } from "../../constants/utils";
import { APP_URL } from "../../constants/Api";
import firebase from "../../constants/Firebase";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import LanguageIcon from "@material-ui/icons/Language";
import EmenuNavlink from "../../components/Emenu/Navlink/EmenuNavlink";
import axios from "axios";
import FormItem from "../../components/Emenu/Form/FormItem";
import { FormProvider, useForm } from "react-hook-form";
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";
import { css } from "@emotion/css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player/lazy";

const useStyles = makeStyles(OnboardingStyle);
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function OnboardingPage() {
  const history = useHistory();
  const menuInfo = useSelector((state) => state.menuInfo);
  const { menuFormSubmitted, selected_language } = menuInfo;
  const {
    ui_strings,
    theme_data,
    name,
    license,
    promotions,
    promotion_on_load,
    forms,
    cover_image,
    skip_onboarding_screen,
  } = menuInfo?.menuData;
  const dispatch = useDispatch();
  const classes = useStyles();
  const alpha = theme_data?.onboarding_screen_title_text_color.replace(
    /^.*,(.+)\)/,
    "$1"
  );

  // For promotion popup dialog
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [promotion_popup] = useState(
    promotions && promotion_on_load && !skip_onboarding_screen
      ? promotions.find((promotion) => promotion.id === promotion_on_load)
      : null
  );

  const [promotionOpen, setOpen] = useState(!!promotion_popup);

  const handlePromotionClose = () => {
    setOpen(false);
  };

  const currentForm =
    forms?.length > 0 &&
    forms.filter((form) => form.display_on_menu_load).length > 0
      ? forms.filter((form) => form.display_on_menu_load)[0]
      : null;

  const [loadingForm, setLoadingForm] = useState(false);
  const [currentFormData, setCurrentFormData] = useState({});
  const methods = useForm({ mode: "onChange" });
  const { isValid, isSubmitting } = methods.formState;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    if (license) {
      firebase.analytics().logEvent("onboarding_page_view", {
        license: license,
        menu: name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (skip_onboarding_screen) {
      history.push({
        pathname: `/home`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip_onboarding_screen]);

  useEffect(() => {
    document.body.style.backgroundColor =
      theme_data?.onboarding_screen_bg_color;
    const rootElement = document.getElementById("root");
    if (rootElement) {
      rootElement.style.backgroundColor =
        theme_data?.onboarding_screen_bg_color;
    }

    if (currentForm && !menuFormSubmitted) {
      setLoadingForm(true);
      axios
        .get(
          `${APP_URL}/${selected_language}/api/feedback/${currentForm.id}/`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setCurrentFormData(response.data);
          }
        })
        .catch((error) => {
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            //console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            //console.log("Error", error.message);
          }
          //console.log(error.config);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuInfo.menuData]);

  if (!menuInfo.menuData) {
    return (
      <div>
        <div
          className={classes.containerWrapper}
          style={{
            backgroundImage:
              license &&
              `url('${APP_URL}/en/menus/${license}/loading-image/launch.png')`,
          }}
        >
          <div className={classes.loadingContainer}>
            <div className={classes.brand}>
              <h1>{!menuInfo.errors ? "Loading" : "Error"}</h1>
              {!menuInfo.loading && menuInfo.errors && (
                <div style={{ fontSize: "14px", padding: "0 40px" }}>
                  Looks like there's an issue with your account. Please contact
                  eMenu support team to resolve this error.
                </div>
              )}
              <h5 className={classes.title}>digitalemenu.com</h5>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    const { enable_language_onboarding } = menuInfo.menuData;
    const handleLanguageSelection = (e) => {
      dispatch(changeLanguage(license, e.target.value));
    };
    const formButtonStyle = {
      backgroundColor: theme_data?.feedback_submit_btn_color,
      color: theme_data?.feedback_submit_btn_text_color,
      fontSize: Number(theme_data?.feedback_submit_btn_font_size),
      ...fontName(theme_data?.feedback_submit_btn_font_family),
      marginTop: 40,
    };
    const onSubmit = (data) => {
      // Get Form Fields
      if (isValid && currentFormData?.id) {
        const submissionData = {};
        submissionData["feedback_id"] = currentFormData?.id;
        submissionData["submission"] = data;

        axios
          .post(
            `${APP_URL}/${selected_language}/api/submission/`,
            JSON.stringify(submissionData),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response.status === 201) {
              dispatch(setMenuFormSubmitted());
            }
          })
          .catch((error) => {
            // Error
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the
              // browser and an instance of
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              //console.log("Error", error.message);
            }
            //console.log(error.config);
          });
      }
    };

    return (
      <div
        className={classes.containerWrapper}
        style={{
          backgroundImage: `url(${cover_image?.cover})`,
        }}
      >
        <Helmet>
          <title>
            {name ? `${name} | digitalemenu.com` : "digitalemenu.com"}
          </title>
        </Helmet>
        <div className={classes.languageSelector}>
          {menuInfo.languages.length > 1 && enable_language_onboarding ? (
            <div className={classes.languageSelector}>
              <Select
                value={selected_language}
                onChange={handleLanguageSelection}
                inputProps={{
                  name: "lang",
                }}
                style={{
                  color:
                    theme_data?.onboarding_screen_language_dropdown_text_color,
                  fontSize: Number(
                    theme_data?.onboarding_screen_language_dropdown_font_size
                  ),
                  ...fontName(
                    theme_data?.onboarding_screen_language_dropdown_font_family
                  ),
                }}
                className={css`
                  &::after,
                  &::before {
                    border-bottom-color: ${theme_data?.onboarding_screen_icon_color};
                  }
                `}
              >
                {menuInfo.languages.map((x, i) => (
                  <MenuItem key={i} value={x.code}>
                    {x.name_local}
                  </MenuItem>
                ))}
              </Select>

              <Icon style={{ color: theme_data?.onboarding_screen_icon_color }}>
                <LanguageIcon />
              </Icon>
            </div>
          ) : null}
        </div>
        <div
          className={classes.onboardingContainer}
          style={
            menuInfo.languages.length > 1 && enable_language_onboarding
              ? { marginTop: "55vh" }
              : null
          }
        >
          <div className={classes.brand}>
            <h1
              style={{
                color: theme_data?.onboarding_screen_title_text_color,
                fontSize: Number(theme_data?.onboarding_screen_title_font_size),
                ...fontName(theme_data?.onboarding_screen_title_font_family),
                visibility: alpha === 0 ? "hidden" : "visible",
              }}
            >
              {menuInfo.menuData.name}
            </h1>
          </div>
          {loadingForm && !menuFormSubmitted ? (
            !isEmpty(currentFormData) ? (
              <div className={classes.formContainer}>
                <FormProvider {...methods}>
                  <form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    className={classes.form}
                  >
                    {currentFormData.form_data.map((item, index) => (
                      <FormItem item={item} key={index} />
                    ))}
                    <Button
                      onClick={methods.handleSubmit(onSubmit)}
                      fullWidth
                      style={formButtonStyle}
                      disabled={!isValid}
                    >
                      {isSubmitting
                        ? ui_strings?.ui_str_feedback_submitting ?? "Submitting"
                        : ui_strings?.ui_str_feedback_submit ?? "Submit"}
                    </Button>
                  </form>
                </FormProvider>
              </div>
            ) : (
              <div>
                <Loader
                  type="Oval"
                  height={50}
                  width={50}
                  className={classes.loader}
                />
              </div>
            )
          ) : (
            <div className={classes.menuButton}>
              <EmenuNavlink to={`/home`}>
                <Button
                  fullWidth
                  type="button"
                  style={{
                    backgroundColor:
                      theme_data?.onboarding_screen_open_menu_button_color,
                    color: theme_data?.onboarding_screen_open_menu_text_color,
                    fontSize: Number(
                      theme_data?.onboarding_screen_open_menu_font_size
                    ),
                    ...fontName(
                      theme_data?.onboarding_screen_open_menu_font_family
                    ),
                  }}
                >
                  {ui_strings?.ui_str_open_menu ?? "Open Menu"}
                </Button>
              </EmenuNavlink>
            </div>
          )}
          {promotion_popup && (
            <Dialog
              fullScreen={fullScreen}
              open={promotionOpen}
              TransitionComponent={Transition}
              keepMounted
              aria-labelledby="variation-modal-title"
              aria-describedby="variation-modal-description"
              onClose={handlePromotionClose}
              onClick={handlePromotionClose}
              classes={{ paper: classes.modal }}
            >
              <DialogTitle
                disableTypography
                style={{
                  backgroundColor: "rgba(0,0,0,0.6)",
                }}
              >
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handlePromotionClose}
                  style={{
                    color: "#fff",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent
                style={{
                  backgroundColor: "rgba(0,0,0,0.6)",
                  paddingBottom: 20,
                }}
              >
                {promotion_popup.video ? (
                  <ReactPlayer
                    url={promotion_popup.video}
                    playing
                    loop
                    playsinline
                    muted
                    controls={false}
                    width={"100%"}
                    height={fullScreen ? "100%" : "80vh"}
                    config={{
                      file: {
                        attributes: {
                          preload: "auto",
                          disablePictureInPicture: true,
                          poster: promotion_popup.image?.product,
                        },
                      },
                    }}
                  />
                ) : (
                  <img
                    style={{
                      width: "100%",
                      height: fullScreen ? "100%" : "80vh",
                      objectFit: "contain",
                    }}
                    src={promotion_popup.image?.product}
                    srcSet={`${promotion_popup.image?.product} 1x,
                    ${promotion_popup.image?.retina} 2x`}
                    alt={"Promotion"}
                  />
                )}
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
    );
  }
}
