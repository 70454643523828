import React from "react";
import Button from "../../CustomButtons/Button";
import { fontName } from "../../../constants/utils";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CheckoutStyle from "../../../views/CheckoutPage/CheckoutStyle";
import { submitOrder } from "../../../store/actions/cart";
import { useHistory } from "react-router-dom";
import ActiveItemConfirmationDialog from "./ActiveOrderTableDialog";

const useStyles = makeStyles(CheckoutStyle);

export default function SubmitOrderButton(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);

  const { handleSubmit, isValid, isSubmitting } = props;
  const {
    ui_strings,
    theme_data,
    enable_user_send_whatsapp,
    enable_whatsapp_order,
    whatsapp_number,
    payment_enabled,
    al_futtaim_enabled,
  } = menuInfo.menuData;
  const {
    submitting,
    paymentType,
    submitOrderError,
    submitOrderErrorMessage,
    loadingCoupon,
    loadingEmployeeDiscount,
    loadingIikoLoyaltyDiscounts,
  } = cartInfo;

  const buttonStyle = {
    backgroundColor: theme_data?.cart_screen_submit_order_btn_color,
    color: theme_data?.cart_screen_submit_order_btn_text_color,
    fontSize: Number(theme_data?.cart_screen_submit_order_btn_font_size),
    ...fontName(theme_data?.cart_screen_submit_order_btn_font_family),
  };

  const whatsAppButtonStyle = {
    backgroundColor: "#25D366",
  };

  const onSubmit = (data) => {
    dispatch(submitOrder(data, history));
  };

  return (
    <div className={classes.submitOrderButtonContainer}>
      {!enable_user_send_whatsapp && (
        <Button
          fullWidth
          style={buttonStyle}
          disabled={
            !isValid ||
            isSubmitting ||
            submitting ||
            loadingCoupon ||
            loadingEmployeeDiscount ||
            loadingIikoLoyaltyDiscounts
          }
          onClick={handleSubmit(onSubmit)}
        >
          {submitting &&
          payment_enabled &&
          paymentType === "card" &&
          !al_futtaim_enabled
            ? ui_strings?.ui_str_redirecting ?? "REDIRECTING ..."
            : submitting
            ? ui_strings?.ui_str_submitting_order ?? "SUBMITTING"
            : !submitting &&
              payment_enabled &&
              paymentType === "card" &&
              !al_futtaim_enabled
            ? ui_strings?.ui_str_pay_now ?? "PAY NOW"
            : ui_strings?.ui_str_submit_order ?? "SUBMIT ORDER"}
        </Button>
      )}
      {enable_whatsapp_order && whatsapp_number && enable_user_send_whatsapp && (
        <Button
          fullWidth
          style={{ ...buttonStyle, ...whatsAppButtonStyle }}
          disabled={
            !isValid ||
            isSubmitting ||
            submitting ||
            loadingCoupon ||
            loadingEmployeeDiscount ||
            loadingIikoLoyaltyDiscounts
          }
          onClick={handleSubmit(onSubmit)}
        >
          {submitting
            ? ui_strings?.ui_str_submitting_order ?? "SUBMITTING"
            : ui_strings?.ui_str_whatsapp_order ?? "WHATSAPP ORDER"}
        </Button>
      )}
      {submitOrderError &&
        (submitOrderErrorMessage?.existing_order ? (
          <ActiveItemConfirmationDialog
            orderData={submitOrderErrorMessage?.existing_order}
          />
        ) : (
          <div className={classes.submitOrderError}>
            "There was an error sending this order. Please try again later."
          </div>
        ))}
    </div>
  );
}
